<script setup lang="ts">
import * as yup from 'yup';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useForm } from 'vee-validate';

// Plugins & Composables
const { t } = useI18n();
const auth = getAuth();
const router = useRouter();

// Data
const loading = ref(false);

// Form
const schema = yup.object().shape({
  email: yup.string().email(t('auth.form.field_email')).required(t('auth.form.field_required')),
});
const { handleSubmit } = useForm({
  validationSchema: schema,
});

const submit = handleSubmit(async (values) => {
  loading.value = true;
  try {
    await sendPasswordResetEmail(auth, values.email);
    router.push('/auth/login?msg=email_reset');
  } catch (error) {
    toastStore().showToast({
      title: t('global.error'),
      message: t('global.error_auth_reset_password'),
      type: 'error',
    });
    throw error;
  }
});
</script>

<template>
  <ui-card class="w-[450px]">
    <div class="w-full flex items-center justify-center flex-col py-2">
      <img class="h-[100px]" src="assets/full-logo.png" />
    </div>
    <form @submit.prevent="submit()">
      <ui-form-input-text name="email" :label="$t('auth.form.email')" placeholder="jean.dupont@mail.com" />

      <ui-button class="w-full mt-6" :loading="loading" @click="submit">
        {{ $t('global.send_reset_email') }}
      </ui-button>
    </form>
  </ui-card>
</template>
